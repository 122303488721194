<portal-page-info
  pageTitle="Authentication Policy"
  [pageDescriptiveTextWithImageWrap]="authRulesDescriptiveText"
  >
</portal-page-info>

<div *ngIf="hasIssuersPermissions !== undefined && !hasIssuersPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div class="auth-rules section">
  <div *ngIf="hasIssuersPermissions">
    <div class="inherited-policy-container">
      <h3 class="inherited-policy-title">Inherited Policy: {{ getInheritedPolicyText()}}</h3>
      <mat-icon 
        class="help-icon" 
        [matTooltip]="getInheritedPolicyTooltipText()"
      >
        help_outline
      </mat-icon>
    </div>

    <div
      *ngIf="!isInheritedPolicy()"
      class="policy-details-container"
    >
      <div class="mfa-policy-container">
        <form 
          class="mfa-policy-form" 
          [formGroup]="mfaPolicyForm" 
          spellcheck="false"
          autocomplete="off"
        >
          <div class="mfa-method-options-container">
            <h3>Multi-Factor Authentication Methods Enabled</h3>

            <portal-page-info
              [productGuideLink]="mfaProductGuideLink"
              pageInfoWidth="full"
            >
            </portal-page-info>

            <!--
            See https://pm.agilicus.com/project/identity-aware-firewall/us/5648?milestone=133
            Commented out
            <mat-checkbox
              formControlName="web_push"
              (change)="mfaMethodChange()"
              aria-label="Checkbox that toggles the web_push option"
            >
              Web-push
            </mat-checkbox>
          -->

            <mat-checkbox
              formControlName="totp"
              (change)="mfaMethodChange()"
              aria-label="Checkbox that toggles the TOTP option"
              [matTooltip]="getTotpTooltipText()"
            >
              Time based challenge app
            </mat-checkbox>

            <mat-checkbox
              formControlName="webauthn"
              (change)="mfaMethodChange()"
              aria-label="Checkbox that toggles the WebAuthN option"
              [matTooltip]="getWebauthnTooltipText()"
            >
              WebAuthn
            </mat-checkbox>
          </div>
        </form>
      </div>

      <div class="policy-catalogue-selection-container">
        <div class="policy-catalogue-selection-left">
          <form 
            class="mfa-policy-catalogue-selection"
            [formGroup]="mfaPolicyCatalogueForm" 
            spellcheck="false"
            autocomplete="off"
          >

            <h3>Authentication Rules</h3>

            <portal-page-info
              [productGuideLink]="authRulesProductGuideLink"
              pageInfoWidth="full"
            >
            </portal-page-info>

            <div class="cards flex-row-wrap flex-layout-gap-2px">
              <span class="card-span" *ngFor="let policyCatalogueEntry of defaultSettings | keyvalue">
                <mat-card appearance="outlined" class="flex-xs-80 flex-sm-60 flex-md-33 flex-lg-25 flex-xl-25">
                  <mat-card-header>
                    <mat-card-title>{{ capitalizeFirstLetter(policyCatalogueEntry.key) }}</mat-card-title>
                    <div class="more-actions">
                      <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openDetailsDialog(policyCatalogueEntry.key, policyCatalogueEntry.value.long_description)">
                          <span>See detailed Information</span>
                        </button>
                      </mat-menu>
                    </div>
                  </mat-card-header>
                  <div class=client-details>
                    <mat-card-subtitle> {{ getSummaryPoint(policyCatalogueEntry.value.long_description) }} </mat-card-subtitle>
                  </div>
                  <div class="set-policy-catalogue-button-container">
                    <button
                      type="button"
                      mat-raised-button
                      color="primary"
                      class="control-button"
                      (click)="openPolicyCatalogueDialog(policyCatalogueEntry.key)"
                      matTooltip="Click to apply the selected policy preset"
                      attr.aria-label="A button that applies the selected policy preset"
                    >
                      CONFIG & APPLY PRESET
                    </button>
                  </div>
                </mat-card>
              </span>
            </div>

            <hr class="separator-line">

            <mat-form-field>
              <mat-label>Default Action</mat-label>
              <mat-select
                #selectDefaultAction
                formControlName="default_action"
                (selectionChange)="modifyPolicyOnFormSelectionChange('default_action')"
                (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectDefaultAction)"
                [matTooltip]="getDefaultActionTooltipText()"
              >
                <mat-option
                  *ngFor="let mfaOption of mFAChallengeAnswerResultOptions"
                  value="{{ mfaOption }}"
                >
                  {{ getDefaultMfaActionDisplayValue(mfaOption) }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </form>
        </div>

      </div>

      <div class="add-group-button-container">
        <button
          type="button"
          mat-raised-button
          color="primary"
          class="control-button"
          (click)="addPolicyGroup()"
          [matTooltip]="getGroupButtonTooltipText()"
          attr.aria-label="A button that adds a new policy group"
          [disabled]="!policyCopy"
        >
          ADD GROUP
        </button>
      </div>

      <div
        *ngIf="!!policyCopy?.spec?.policy_groups && policyCopy.spec.policy_groups.length !== 0"
        class="expansion-container policy-group-expansion-container"
        cdkDropListGroup
      >
        <mat-accordion
          multi
          cdkDropList
          #policyGroupList="cdkDropList"
          [cdkDropListData]="policyCopy.spec.policy_groups"
          (cdkDropListDropped)="dropGroup($event)"
        >
          <mat-expansion-panel
            *ngFor="let policyGroup of policyCopy.spec.policy_groups; trackBy: trackByGroupId"
            [expanded]="getPanelState(policyGroup.metadata?.id)"
            (opened)="onPanelOpen(policyGroup.metadata?.id)"
            (closed)="onPanelClose(policyGroup.metadata?.id)"
            cdkDrag
          >
            <mat-expansion-panel-header
              cdkDropList
              #policyRuleList="cdkDropList"
              [cdkDropListData]="policyGroup.spec.rule_ids"
              [cdkDropListConnectedTo]="cdkDropTrackLists"
              (cdkDropListDropped)="dropRule($event, policyGroup)"
            >
              <mat-panel-title>
                <span *ngIf="!getPanelState(policyGroup.metadata?.id)">{{ policyGroup.spec.name }}</span>
              </mat-panel-title>
              <mat-panel-description>
                <button
                  type="button"
                  mat-raised-button
                  color="warn"
                  class="control-button"
                  (click)="deletePolicyGroup(policyGroup); $event.stopPropagation();"
                  matTooltip="Delete this policy group"
                  attr.aria-label="A button that deletes the policy group"
                >
                  DELETE GROUP
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              *ngIf="getPanelState(policyGroup.metadata?.id)"
              class="group-container"
              cdkDropList
              #policyRuleList="cdkDropList"
              [cdkDropListData]="policyGroup.spec.rule_ids"
              [cdkDropListConnectedTo]="cdkDropTrackLists"
              (cdkDropListDropped)="dropRule($event, policyGroup)"
            >
              <mat-form-field class="main-input-field">
                <mat-label>Group Name</mat-label>
                <input
                  matInput
                  type="text"
                  value="{{ policyGroup.spec.name }}"
                  (blur)="updateGroupName($event.target.value, policyGroup)"
                  (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                  spellcheck="true"
                />
              </mat-form-field>

              <div class="add-rule-container">
                <div class="add-rule-button-container">
                  <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    class="control-button"
                    (click)="addPolicyRuleToGroup(policyGroup.metadata?.id)"
                    matTooltip="Add a new rule to the policy"
                    attr.aria-label="A button that adds a new policy rule"
                  >
                    ADD RULE
                  </button>
                </div>
              </div>

              <div class="verical-tabs">
                <mat-tab-group>
                  <div *ngIf="!!policyRulesList">
                    <mat-tab *ngFor="let policyRuleId of policyGroup.spec.rule_ids"
                      label="{{getPolicyRuleFromId(policyRuleId)?.spec?.name}}"> 
                      <portal-auth-issuer-policy-rule
                        [currentPolicyRule]="getPolicyRuleFromId(policyRuleId)"
                        [currentPolicyGroup]="policyGroup"
                        [groupIdToGroupMap]="groupIdToGroupMap"
                        [groupDisplayNameToGroupMap]="groupDisplayNameToGroupMap"
                      >
                      </portal-auth-issuer-policy-rule>
                    </mat-tab>
                  </div>
                
                </mat-tab-group>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>